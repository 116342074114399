import './_UserFormRoleDetails.scss'
import UFSelect from '../../../../../../Common/UseForm/UFSelect/UFSelect'
import { useTranslation } from 'react-i18next'
import { DivFlex } from '../../../../../../../theme/eskolaTheme'
import { IUser } from '../../../IUser'

import { useAuth } from '../../../../../../../contexts/AuthContext'
import PaginatedAsyncAutocomplete from '../../../../../../Common/UseForm/PaginatedAsyncAutocomplete/PaginatedAsyncAutocomplete'
import UFOrderListSelect from '../../../../../../Common/UseForm/UFOrderListSelect/UFOrderListSelect'
import { Container } from '@mui/material'

interface UserFormRoleDetailsProps {
    control: any
    disabled: boolean
    setValue: any
    item: IUser
}

const UserFormRoleDetails = (props: UserFormRoleDetailsProps) => {
    const { t } = useTranslation()
    const { control, disabled, setValue, item } = props

    const { user } = useAuth()

    return (
        <Container>
            <PaginatedAsyncAutocomplete
                name="OrganizationID"
                componentLabel={t('Organization')}
                control={control}
                setValue={setValue}
                url={user!.isSuperUser ? 'organizations' : 'organization/' + user!.OrganizationID.toString()}
                defaultValue={{
                    id: typeof item === 'undefined' ? -1 : item.OrganizationID,
                    label: user!.OrganizationDSCR.toString(),
                }}
                disabled={user!.isSuperUser ? disabled : true}
                required={t('Empty field!')}
            />

            <UFSelect
                setValue={setValue}
                name="RoleID"
                control={control}
                label={t('Role')}
                idColName="RoleID"
                nameColName="Name"
                url={'organizations/' + user!.OrganizationID + '/roles'}
                disabled={disabled}
                required={t('Required value')}
            />

            <UFOrderListSelect
                name="Subjects"
                setValue={setValue}
                url={
                    typeof item.UserID === 'undefined'
                        ? 'organizations/' + user!.OrganizationID + '/subjects'
                        : 'users/' + item.UserID + '/usersubjectsselectedunselected'
                }
            />
        </Container>
    )
}

export default UserFormRoleDetails
