// import './_SignUp.scss'
import React, { useEffect, useRef, useState } from 'react'
import { Button, Grid, Typography, Box, Link, Divider } from '@mui/material'
import { useTranslation } from 'react-i18next'
import UFTextField from '../../../Common/UseForm/UFTextField/UFTextField'
import { useForm } from 'react-hook-form'
import { ISignUp } from './ISignUp'
import { EInputType } from '../../../Common/Interfaces/IInputProps'
import { useLoading } from '../../../../contexts/LoadingContext'
import { useAlert } from '../../../../contexts/AlertContext'
import { GoogleLogin, CredentialResponse } from '@react-oauth/google'
import { jwtDecode } from 'jwt-decode'
import Onboarding from '../../../../layouts/Onboarding/Onboarding'
import api from '../../../../Utils/APIService'

const SignUp: React.FC = () => {
    const { t, i18n } = useTranslation()

    const { setLoading, isLoading } = useLoading()
    const methods = useForm<ISignUp>({ defaultValues: { email: '', password: '', name: '', phone: '', address: '' } })
    const { handleSubmit, control, setValue, resetField, watch } = methods
    const { setAlert } = useAlert()

    const emailValue = watch('email')

    const [buttonWidth, setButtonWidth] = useState(0)
    const elementRef = useRef<HTMLButtonElement>(null)

    useEffect(() => {
        if (elementRef.current) {
            const elementWidth = elementRef.current.getBoundingClientRect().width
            setButtonWidth(elementWidth)
        }
    }, [])

    useEffect(() => {
        if (emailValue === '') {
            resetField('password')
            resetField('confirmpassword')
        }
    }, [emailValue, resetField])

    const handleSignup = async (prm: ISignUp) => {
        try {
            if (prm.password !== prm.confirmpassword) {
                setAlert({
                    message: 'Passwords do not match.',
                    severity: 'error',
                    datetime: Date.now().toString(),
                })
                return
            }

            const prmItem = {
                Email: prm.email,
                Password: prm.password,
                Name: prm.name,
                Phone: prm.phone,
                Address: prm.address,
            }

            setLoading(true)

            const OptionsLogin = {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Origin: process.env.REACT_APP_ORIGIN + '',
                },
            }

            const response = await api.post('users/signup', JSON.stringify(prmItem))

            if (!response.data) {
                const errorData = response.data
                console.log(JSON.stringify(errorData))

                setLoading(false)
                setAlert({
                    message: errorData.message,
                    severity: 'error',
                    datetime: Date.now().toString(),
                })
            } else {
                setLoading(false)
                setAlert({
                    message: t('Check your email to activate your account'),
                    severity: 'success',
                    datetime: Date.now().toString(),
                })
            }
        } catch (err) {
            //setAlertMessage(t('Error while loading'))
            //setAlertOpened(true)
            setLoading(false)
            setAlert({
                message: t('Error while loading'),
                severity: 'error',
                datetime: Date.now().toString(),
            })
        }
    }

    const handleSuccess = (credentialResponse: CredentialResponse) => {
        if (credentialResponse && credentialResponse.credential) {
            console.log('There is some credential')
            console.log(JSON.stringify(credentialResponse))
            const decodedToken = jwtDecode<any>(credentialResponse.credential)

            alert('We are going to signup directly with Google')
            // Now you have the user's details
            console.log('User Picture:', decodedToken.picture)
        }
    }

    const handleError = () => {
        alert('test failed')
        console.error('Login failed')
    }

    return (
        <>
            <Onboarding>
                <Typography component="h1" variant="h5" align="center" sx={{ mb: 2 }}>
                    {t('Register')}
                </Typography>
                <GoogleLogin
                    onSuccess={handleSuccess}
                    onError={handleError}
                    text="signup_with"
                    locale={i18n.language}
                    width={buttonWidth}
                />
                <Divider sx={{ mt: 2, mb: 2 }}>
                    <Typography component="span" variant="body2" align="center" sx={{ mb: 2 }}>
                        OR
                    </Typography>
                </Divider>
                <Box component="form" onSubmit={handleSubmit(handleSignup, handleError)} noValidate sx={{ mt: 1 }}>
                    {/* <UFTextField
                        setValue={setValue}
                        control={control}
                        fullWidth={true}
                        name="name"
                        label={t('Name')}
                        required={t('Empty field!')}
                        autoComplete={'name'}
                        autoFocus={true}
                        inputType={EInputType.Text}
                    /> */}

                    {/* <UFTextField
                        setValue={setValue}
                        control={control}
                        fullWidth={true}
                        name="Surname1"
                        label={t('Surname1')}
                        required={t('Empty field!')}
                        autoComplete={'Surname'}
                        autoFocus={true}
                        inputType={EInputType.Text}
                    /> */}

                    <UFTextField
                        setValue={setValue}
                        control={control}
                        fullWidth={true}
                        name="email"
                        label={t('Email')}
                        required={t('Empty field!')}
                        autoComplete={'email'}
                        autoFocus={true}
                        inputType={EInputType.Email}
                    />
                    <UFTextField
                        setValue={setValue}
                        control={control}
                        fullWidth={true}
                        name="password"
                        label={t('Password')}
                        required={t('Empty field!')}
                        autoComplete={'password'}
                        autoFocus={true}
                        inputType={EInputType.Password}
                    />
                    <UFTextField
                        setValue={setValue}
                        control={control}
                        fullWidth={true}
                        name="confirmpassword"
                        label={t('Repeat Password')}
                        required={t('Empty field!')}
                        inputType={EInputType.Password}
                    />

                    <Button
                        ref={elementRef}
                        data-test="signin-button"
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        {t('Register')}
                    </Button>

                    <Grid container>
                        <Grid item xs>
                            <Typography variant="body2" align="center">
                                Do you have already an account?{' '}
                                <Link underline="none" href="/login">
                                    {t('Login')}
                                </Link>
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Onboarding>
        </>
    )
}

export default SignUp
