import {
    Container,
    IconButton,
    Typography,
    Toolbar,
    Box,
    Tooltip,
    SelectChangeEvent,
    Select,
    MenuItem,
    Checkbox,
    ListSubheader,
} from '@mui/material'
import { t } from 'i18next'
import { useCurrentScreen } from '../../../../contexts/CurrentScreenContext'
import CustomSVG from '../../CustomSVG/CustomSVG'
import { useTranslation } from 'react-i18next'
import { IAction } from '../../MenuActions/IAction'
import { ALLSELECTEDICON, NONESELECTEDICON } from '../../../../styles/_icons'
import { useState } from 'react'
import { motion } from 'framer-motion'

interface ListToolBarProps {
    handleSelectAll?: () => void
    hideMultiSelect?: boolean
    checkedItems?: number[]
}

const ListToolBar: React.FC<ListToolBarProps> = (props: ListToolBarProps) => {
    const { handleSelectAll, hideMultiSelect, checkedItems } = props
    const { actions, hasActionsRequireBulk, handleSelectedAction, currentScreenItem, selectedAction } =
        useCurrentScreen()
    const { t } = useTranslation()
    const [selectAll, setSelectAll] = useState(false)
    const handleSelectAction = (action: IAction) => () => {
        handleSelectedAction(action.shortCode)
    }

    const handleSelectAllItems = () => {
        setSelectAll(!selectAll)
        handleSelectAll!()
    }

    const buttonVariants = {
        hover: { scale: 1.9 },
        tap: { scale: 0.95 },
    }

    const [selectedValue, setSelectedValue] = useState<string>('')

    const handleChange = (event: SelectChangeEvent) => {
        setSelectedValue(event.target.value)
        handleSelectedAction(event.target.value)
    }

    return (
        <Box className="listToolBar">
            <Container
                className="containerTool"
                sx={{ paddingTop: 0, paddingLeft: 0, paddingRight: 0, paddingBottom: 0, marginTop: 0 }}
            >
                <Box
                    className="bulkActionsContainer"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                >
                    {hasActionsRequireBulk && !hideMultiSelect && (
                        // Select all checkbox
                        <Box display="flex" justifyContent="center" alignItems="center">
                            <Tooltip title="Select all">
                                <Checkbox
                                    onClick={handleSelectAllItems}
                                    color="primary"
                                    checked={selectAll}
                                    size="small"
                                    className="selectAll"
                                />
                            </Tooltip>
                            <Typography variant="body2" component="label" onClick={handleSelectAllItems}>
                                {selectAll ? t('Deselect all') : t('Select all')}{' '}
                                {checkedItems && checkedItems?.length > 0 ? `(${checkedItems?.length})` : ''}
                            </Typography>
                        </Box>
                    )}
                    {/* Why is this condition repeated???? */}
                    {!hasActionsRequireBulk && !hideMultiSelect && (
                        <Box display="flex" justifyContent="center" alignItems="center">
                            <IconButton>
                                <div></div>
                            </IconButton>
                        </Box>
                    )}
                    {!hideMultiSelect && currentScreenItem.mode === 'list' && (
                        <Toolbar variant="dense" component="nav" className="toolbar">
                            <>
                                {actions!.length <= 4 &&
                                    actions!.map(menuItem => (
                                        <Tooltip key={menuItem.shortCode + '_tooltip'} title={menuItem.label}>
                                            <motion.div variants={buttonVariants} whileHover="hover" whileTap="tap">
                                                <IconButton
                                                    key={menuItem.shortCode + '_icon'}
                                                    onClick={handleSelectAction(menuItem as IAction)}
                                                    disabled={
                                                        Boolean(menuItem.requiresBulkRecordsSelected) &&
                                                        checkedItems &&
                                                        checkedItems.length === 0
                                                    }
                                                >
                                                    <CustomSVG
                                                        key={menuItem.shortCode + '_svg'}
                                                        strSVG={menuItem.icon}
                                                    />
                                                </IconButton>
                                            </motion.div>
                                        </Tooltip>
                                    ))}

                                {actions!.length > 4 && (
                                    <Select
                                        value={selectedAction === null ? '' : selectedValue}
                                        onChange={handleChange}
                                        displayEmpty
                                        renderValue={value => {
                                            if (!value && selectedAction === null)
                                                return (
                                                    <Typography color="textSecondary" component="span" variant="body2">
                                                        {t('Select an action')}
                                                    </Typography>
                                                )
                                            const selectedItem =
                                                selectedAction === null
                                                    ? ''
                                                    : actions!.find(item => item.shortCode === value)
                                            return (
                                                selectedAction !== null && (
                                                    <Box display="flex" alignItems="center" gap="0.25rem">
                                                        <CustomSVG
                                                            key={selectedItem && selectedItem?.shortCode + '_svg'}
                                                            strSVG={(selectedItem && selectedItem?.icon + '') || ''}
                                                        />
                                                        <Typography marginLeft={1} component="span" variant="body2">
                                                            {selectedItem && selectedItem?.label}
                                                        </Typography>
                                                    </Box>
                                                )
                                            )
                                        }}
                                    >
                                        <MenuItem value="" key="-1">
                                            {t('Select an action')}
                                        </MenuItem>
                                        {actions!.map((item, index) => (
                                            <div key={'bulk_key_' + index}>
                                                {index === 0 && (
                                                    <ListSubheader
                                                        sx={{
                                                            fontSize: '0.875rem',
                                                            fontStyle: 'italic',
                                                            fontWeight: '300',
                                                        }}
                                                        key="bulk"
                                                    >
                                                        {t('Bulk (requires selection)')}
                                                    </ListSubheader>
                                                )}

                                                {Boolean(item.requiresBulkRecordsSelected) && (
                                                    <MenuItem
                                                        key={item.shortCode}
                                                        value={item.shortCode}
                                                        disabled={
                                                            Boolean(item.requiresBulkRecordsSelected) &&
                                                            checkedItems &&
                                                            checkedItems.length === 0
                                                        }
                                                    >
                                                        <Box display="flex" alignItems="center" gap="0.25rem">
                                                            <CustomSVG
                                                                key={item.shortCode + '_svg'}
                                                                strSVG={item.icon}
                                                            />
                                                            <Typography marginLeft={1} component="span" variant="body2">
                                                                {item.label}
                                                            </Typography>
                                                        </Box>
                                                    </MenuItem>
                                                )}
                                            </div>
                                        ))}
                                        {actions!.map((item, index) => (
                                            <div key={'action_key_' + index}>
                                                {index === 0 && (
                                                    <ListSubheader
                                                        sx={{
                                                            fontSize: '0.875rem',
                                                            fontStyle: 'italic',
                                                            fontWeight: '300',
                                                        }}
                                                        key="action"
                                                    >
                                                        {t('Direct Action (no selection required)')}
                                                    </ListSubheader>
                                                )}

                                                {Boolean(item.requiresBulkRecordsSelected) === false && (
                                                    <MenuItem
                                                        key={item.shortCode}
                                                        value={item.shortCode}
                                                        disabled={
                                                            Boolean(item.requiresBulkRecordsSelected) &&
                                                            checkedItems &&
                                                            checkedItems.length === 0
                                                        }
                                                    >
                                                        <Box display="flex" alignItems="center" gap="0.25rem">
                                                            <CustomSVG
                                                                key={item.shortCode + '_svg'}
                                                                strSVG={item.icon}
                                                            />
                                                            <Typography marginLeft={1} component="span" variant="body2">
                                                                {item.label}
                                                            </Typography>
                                                        </Box>
                                                    </MenuItem>
                                                )}
                                            </div>
                                        ))}
                                    </Select>
                                )}
                            </>
                        </Toolbar>
                    )}
                </Box>
            </Container>
        </Box>
    )
}

export default ListToolBar
